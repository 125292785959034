import React from 'react';
import type { Account } from '@wilm/shared-types/account';
import LoginForm from './login-form';

export interface LoginProps {
    onLogin?: (account: Account) => void;
    closeLoginModal?: () => void;
    registrationNoteContent: string;
    accessDetailsEnabled: boolean;
    accessDetailsLabel: string;
    companyDetailsEnabled: boolean;
    companyDetailsLabel: string;
    contactDetailsEnabled: boolean;
    contactDetailsLabel: string;
    personalDetailsEnabled: boolean;
    personalDetailsLabel: string;
    inputLabels: { name: string; label: string }[];
}

const Login: React.FC<LoginProps> = ({
    onLogin,
    registrationNoteContent,
    closeLoginModal,
    accessDetailsEnabled,
    accessDetailsLabel,
    companyDetailsEnabled,
    companyDetailsLabel,
    contactDetailsEnabled,
    contactDetailsLabel,
    personalDetailsEnabled,
    personalDetailsLabel,
    inputLabels
}) => {
    return (
        <LoginForm
            onLogin={onLogin}
            registrationNoteContent={registrationNoteContent}
            closeLoginModal={closeLoginModal}
            registrationFormLabelSettings={{
                accessDetailsEnabled,
                accessDetailsLabel,
                companyDetailsEnabled,
                companyDetailsLabel,
                contactDetailsEnabled,
                contactDetailsLabel,
                personalDetailsEnabled,
                personalDetailsLabel,
                inputLabels
            }}
        />
    );
};

export default Login;
